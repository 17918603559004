/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import Form from './form';

import Nnris from 'assets/images/nnris.png';
import NnrisBig from 'assets/images/nnris_big.png';
import Nutifood from 'assets/images/nutifood.png';
import Image from 'components/Image';

const HomeScreen: React.FC = () => (
  <>
    <div className="p-home" style={{ width: window.innerWidth, height: window.innerHeight }}>
      <div className="panel">
        <div className="logo">
          <div className="nutifood">
            <Image imgSrc={Nutifood} ratio="nutifood" />
          </div>
          <div className="nnris">
            <Image imgSrc={Nnris} ratio="nnris" />
          </div>
        </div>
        <Form />

      </div>
    </div>
  </>
);

export default HomeScreen;
