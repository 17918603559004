/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import WrapButton from 'assets/images/button.svg';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Pulldown, { OptionType } from 'components/Pulldown';
import { apiSaveCheckIn, apiSendOTP } from 'services/request/customer';

export interface FormPayload {
  name: string;
  date: Date;
  product: OptionType | null; // Update later
  phone: string;
  otp: string;
}

interface ErrorResponse {
  checkInDate?: string;
  code: number;
  customerId: number;
  message: string;
}

interface FormProps {
}

const OPTIONS_PRODUCTS = [
  {
    value: 'Varna Complete',
    label: 'Varna Complete',
  },
  {
    value: 'Varna Diabetes',
    label: 'Varna Diabetes',
  },
  {
    value: 'Nuvi Grow',
    label: 'Nuvi Grow',
  },
  {
    value: 'Famna',
    label: 'Famna',
  },
  {
    value: 'Grow Plus Vàng',
    label: 'Grow Plus Vàng',
  },
  {
    value: 'Grow Plus Trắng',
    label: 'Grow Plus Trắng',
  },
  {
    value: 'NuVi Sữa Thạch',
    label: 'NuVi Sữa Thạch',
  },
  {
    value: 'NuVi Sữa Nước',
    label: 'NuVi Sữa Nước',
  },
  {
    value: 'NuVi Sữa Uống Lên Men',
    label: 'NuVi Sữa Uống Lên Men',
  },
  {
    value: 'NuVi Jelly',
    label: 'NuVi Jelly',
  },
];

const Form: React.FC<FormProps> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isRequestOTP, setRequestOTP] = useState(false);
  const [time, setTime] = useState(60);
  const [isRepeat, setRepeat] = useState(false);
  // const []
  const method = useForm<FormPayload>({
    defaultValues: {
      name: '',
      phone: '',
      date: undefined,
      product: null,
      otp: '',
    },
    resolver: yupResolver(yup.object({
      name: yup.string().required('Thông tin bắt buộc !!'),
      date: yup.mixed().required('Thông tin bắt buộc !!'),
      product: yup.mixed().required('Thông tin bắt buộc !!'),
      phone: yup.string().required('Thông tin bắt buộc !!')
        .matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/, 'Không đúng định dạng !!'),
      otp: yup.string().min(6, 'Mã xác thực không hợp lệ !!').max(6, 'Mã xác thực không hợp lệ !!').when('phone', {
        is: (val: string) => !!val,
        then: yup.string().required('Thông tin bắt buộc !!'),
      }),
    })),
  });

  const submitForm = async (params: FormPayload) => {
    try {
      setLoading(true);
      FingerprintJS.load({
        apiKey: 'wiNxT1yNyXIxwm9F28Gx', region: 'ap',
      })
        .then((fp) => fp.get())
        .then((result) => {
          (window as any).constellar?.action('checkin', { ...params, vid: result.visitorId });
        });

      const res = await apiSaveCheckIn({
        ...params,
        year_of_birth: params.date?.getFullYear(),
        interested_products: params.product?.value.toString() || '',
      });
      history.push(`/check-in/${res.id}`);
    } catch (error) {
      if (Array.isArray(error)) {
        const isErrorPhone = error?.find((e) => e?.code === 1000);
        const isErrorOTP = error?.find((e) => e?.code === 1002);
        if (isErrorPhone) {
          setRequestOTP(false);
          setTime(60);
          setRepeat(false);
          method.setError('phone', {
            type: 'value',
            message: 'Số điện thoại đã tồn tại !!',
          });
        } else if (isErrorOTP) {
          method.setError('otp', {
            type: 'value',
            message: 'Mã xác thực không hợp lệ, vui lòng kiểm tra lại !!',
          });
        } else {
          toast.error('Lỗi hệ thống !!');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRequestOTP || isRepeat) {
      interval = setInterval(() => {
        setTime((prev) => {
          if (prev - 1 === 0) {
            clearInterval(interval);
            setRepeat(false);
          }
          if (prev > 0) {
            return prev - 1;
          }
          return 0;
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isRequestOTP, isRepeat]);

  const requestOTP = async () => {
    try {
      const phone = method.getValues('phone');
      await apiSendOTP({ phone });
      toast.success('Gửi mã xác thực thành công !!');
      setRequestOTP(true);
      method.clearErrors('phone');
    } catch (error) {
      setRequestOTP(false);
      if (Array.isArray(error)) {
        const isErrorPhone = error?.find((e) => e?.code === 1000);
        if (isErrorPhone) {
          setRequestOTP(false);
          setTime(60);
          setRepeat(false);
          toast.error('Số điện thoại đã tồn tại !!');
          method.setError('phone', {
            type: 'value',
            message: 'Số điện thoại đã tồn tại !!',
          });
        } else {
          toast.error('Lỗi hệ thống !!');
        }
      }
    }
  };

  const repeatOTP = async () => {
    try {
      const phone = method.getValues('phone');
      await apiSendOTP({ phone });
      toast.success('Gửi mã xác thực thành công !!');
      method.clearErrors('phone');
      if (!time) {
        setTime(60);
        setRepeat(true);
      }
    } catch (error) {
      toast.error('Lỗi hệ thống !!');
    }
  };

  return (
    <div className="form">
      <FormProvider {...method}>
        <form onSubmit={method.handleSubmit(submitForm)} noValidate>
          <h2>THÔNG TIN KHÁCH HÀNG</h2>

          <div className="field">
            <label htmlFor="name">HỌ TÊN KHÁCH HÀNG</label>
            <Controller
              name="name"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <input autoComplete="off" type="text" {...field} id="name" name="name" />
                  {error?.message && (
                    <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className="field">
            <label htmlFor="date">NĂM SINH</label>
            <Controller
              name="date"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <DatePicker
                    className="datepicker"
                    selected={field.value}
                    onChange={(e) => field.onChange(e)}
                    showYearPicker
                    dateFormat="yyyy"
                  />
                  {error?.message && (
                    <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className="field">
            <label htmlFor="address">SẢN PHẨM ĐANG QUAN TÂM</label>
            <Controller
              name="product"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Pulldown
                    options={OPTIONS_PRODUCTS}
                    onChange={field.onChange}
                    value={field.value}
                    // {...field}
                  />
                  {error?.message && (
                  <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className="field">
            <label htmlFor="phone">SỐ ĐIỆN THOẠI</label>
            <Controller
              name="phone"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <div className="phone">
                    <input autoComplete="off" type="number" pattern="\d*" {...field} id="phone" name="phone" />
                    <button type="button" disabled={isRequestOTP} onClick={requestOTP}>NHẬN MÃ OTP</button>
                  </div>
                  {error?.message && (
                    <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className="field">
            <label htmlFor="phone">MÃ OTP</label>
            <Controller
              name="otp"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <input autoComplete="off" type="number" pattern="\d*" {...field} id="otp" name="otp" />
                  {error?.message && (
                    <span>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          {isRequestOTP && (
            <div className="countdown">
              <span>
                Thời gian còn lại
              </span>
              {' '}
              <b>
                {time}
                s
              </b>
              <br />
              <span>
                Không nhận được mã OTP
              </span>
              {' '}
              <b className="action" aria-disabled={!time} onClick={repeatOTP}>Gửi lại mã</b>
            </div>
          )}

          <div className="submit">
            <button type="submit">
              <Image imgSrc={WrapButton} ratio="submit" />
              {!loading ? (
                <span>
                  GỬI THÔNG TIN
                </span>
              ) : (
                <Icon iconName="loading" />
              )}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;
