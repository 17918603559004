import React from 'react';

import mapModifiers from 'utils/functions';

const iconList = {
  loading: 'loading',
  arrowDown: 'arrowDown',
};

export type IconName = keyof typeof iconList;

interface IconProps {
  iconName: IconName;
}

const Icon: React.FC<IconProps> = ({ iconName }) => (
  <div className={mapModifiers('a-icon', iconName)} />
);

export default Icon;
