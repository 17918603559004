import 'App.scss';

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { initGTM } from 'GoogleTagManager';
import useConstellar from 'hooks/useConstellar';
import routes from 'routes';
import { store } from 'store';

initGTM();

const NotFound: React.FC = () => <div>Not Found</div>;

const App: React.FC = () => {
  useConstellar();

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          exact
          path={route.path}
          key={route.id}
          component={route.component}
        />
      ))}
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <App />

    </Router>
  </Provider>
);

export default AppWrapper;
