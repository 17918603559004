/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useRef, useState } from 'react';

import Icon from 'components/Icon';
import useClickOutside from 'hooks/useClickOutside';
import mapModifiers from 'utils/functions';

export type OptionType = {
  label: string;
  value: string | number;
};

export interface PulldownProps {
  options?: OptionType[];
  placeholder?: string;
  value?: OptionType | null;
  error?: string;
  onChange?: (option?: OptionType) => void;
}

const Pulldown: React.FC<PulldownProps> = ({
  options,
  placeholder = '',
  onChange,
  value,
  error,
}) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleSelect = useCallback((item: OptionType) => {
    if (onChange) onChange(item);
    if (open) setOpen(false);
  }, [onChange, open]);

  useClickOutside(mainRef, () => {
    if (open) setOpen(false);
  });

  return (
    <div
      ref={mainRef}
      className={mapModifiers(
        'm-pulldown',
        open && 'open',
        error && 'error',
      )}
    >
      <div tabIndex={0} role="button" className="m-pulldown_header" onClick={handleOpen}>
        {placeholder && !value ? <div className="m-pulldown_placeholder">{placeholder}</div>
          : (<div className="m-pulldown_title">{value?.label}</div>)}

        <div className="m-pulldown_chervon">
          <Icon iconName="arrowDown" />
        </div>
      </div>

      <div className="m-pulldown_options">
        {options && options.length > 0 && options.map((item, index) => (
          <div
            tabIndex={0}
            role="button"
            className="m-pulldown_option"
            key={`option-${index.toString()}`}
            onClick={() => handleSelect(item)}
          >
            <p>
              {item.label}
            </p>
            {item?.value === value?.value && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12" /></svg>
            )}
          </div>
        ))}
      </div>

      {error && (
        <div className="m-pulldown_error">
          {error}
        </div>
      )}

    </div>
  );
};

export default Pulldown;
